import settings from "../config/config";

export const contractStatuses = {
  Active: "ACTIVE",
  Signed: "SIGNED",
  Expired: "EXPIRED",
};

export const invoiceStatuses = {
  Overdue: "OVERDUE",
  Financed: "FINANCED",
  NotSubmitted: "NOT SUBMITTED",
  Submitted: "SUBMITTED",
  Rejected: "REJECTED",
  PartiallyPaid: "PARTIALLY PAID",
  Paid: "PAID",
  Closed: "CLOSED",
};

export const factoringProductTypes = {
  Reverse: "REVERSE",
};

export const logoutReasons = {
  SessionExpired: "SessionExpired",
};

export const waveStepTypes = {
  Day: "DAY",
  Month: "MONTH",
};

export const permissionTypes = {
  ManageContract: "MANAGE_CONTRACT",
  AddBuyer: "ADD_BUYER",
  UploadInvoices: "UPLOAD_INVOICES",
  ManageUsers: "MANAGE_USERS",
  ApproveInvoices: "APPROVE_INVOICES",
  SignContract: "SIGN_CONTRACT",
};

export const factoringPermissions = {
  read: "roleReadInformation",
  upload_invoices: "roleUploadInvoices",
  approve_invoices: "roleApproveInvoices",
  add_buyer: "roleAddBuyer",
  manage_contract: "roleManageContract",
  manage_users: "roleManageUsers",
};

export const loanPermissions = {
  read: "roleReadInformation",
  manage_users: "roleManageUsers",
};

export const contractFunctionIsActive = (status) => {
  return settings.contractFunctionsActiveStatuses !== ""
    ? settings.contractFunctionsActiveStatuses.split(",").some((i) => i === status)
    : true;
};

export const billPaymentTypes = {
  Payment: "PAYMENT",
  Prepayment: "PREPAYMENT",
  CreditNote: "CREDITNOTE",
};
