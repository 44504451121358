import { logoutReasons } from "../utils/statusUtils";

const ApiWrapper = (response, dispatch) => {
  if (response?.code === "SME6" || response?.code === "SME5" || response?.code === "SME3") {
    dispatch({
      type: "LOGOUT",
      payload: { reason: logoutReasons.SessionExpired },
    });
    return;
  }

  return response;
};

export default ApiWrapper;
