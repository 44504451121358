export const reformatUnderscore = (key) =>
  key
    .toLowerCase()
    .split("_")
    .map((item, i) => (i === 0 ? item : item.charAt(0).toUpperCase() + item.slice(1)))
    .join("");

export const removeTitles = () => {
  const titles = document.querySelectorAll("*[title]");

  titles.forEach((title) => {
    title.removeAttribute("title");
  });
};

export const filterArrOfObject = (array, key) => {
  return array.filter((item, ind) => array.indexOf(item) === ind && array.find((it) => it[key] === item[key]) === item);
};

export const waitForGlobal = (key, callback) => {
  if (window[key]) {
    callback();
    return;
  }
  setTimeout(function () {
    waitForGlobal(key, callback);
  }, 100);
};

export const generateTranslationString = (text, prefix) => {
  return `g4rForm${prefix || ""}${text
    // remove diacritics
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    // convert to CamelCase
    .split(new RegExp(/\/|\s/g))
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("")}`;
};
