import settings from "../config/config";
import { getCmsAuth } from "./guardService";

export const getLegalTexts = async (lang) => {
  try {
    const response = await fetch(
      `${settings.backendApiUrl}/cms/legal-text?language=${lang}`,
      getCmsAuth()
    );

    const items = await response.json();

    return items;
  } catch (e) {
    return { error: true, message: e };
  }
};
