import React, { useState, useEffect } from "react";
import { Form, Input } from "antd";
import i18next from "i18next";

import { requiredField, defaultLabelText } from "../../../utils/formUtils";

const { TextArea } = Input;

const CustomTextArea = ({ label, name, inlineLabel, onValueChange, rules, value, ...rest }) => {
  const defaultPlaceholderText = rules?.length && rules[0]?.required ? requiredField(label) : label;
  const [currentValue, setCurrentValue] = useState(null);
  const [labelText, setLabelText] = useState(defaultLabelText);
  const [placeholderText, setPlaceholderText] = useState(defaultPlaceholderText);

  const language = i18next.language;

  useEffect(() => {
    setLabelText(
      currentValue ? (rules?.length && rules[0]?.required ? requiredField(label) : label) : defaultLabelText
    );

    setPlaceholderText(currentValue ? "" : defaultPlaceholderText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentValue]);

  return inlineLabel ? (
    <>
      {inlineLabel && <label>{labelText}</label>}
      <TextArea
        className={`textarea ${currentValue && "textarea--active"}`}
        placeholder={placeholderText}
        bordered={false}
        onChange={(event) => {
          setCurrentValue(event.target.value);
          onValueChange && onValueChange(event.target.value);
        }}
        value={value}
        {...rest}
      />
    </>
  ) : (
    <Form.Item label={labelText} name={name} rules={rules}>
      <TextArea
        className={`textarea ${currentValue && "textarea--active"}`}
        placeholder={placeholderText}
        bordered={false}
        onChange={(event) => {
          setCurrentValue(event.target.value);
          onValueChange && onValueChange(event.target.value);
        }}
        value={value}
        {...rest}
      />
    </Form.Item>
  );
};

export default CustomTextArea;
