import dev from "./dev.json";
import prod from "./prod.json";
import test from "./test.json";

let useConf;

const stageValue = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE.trim()
  : process.env.REACT_APP_STAGE;
switch (stageValue) {
  case "prod":
    useConf = prod;
    break;
  case "dev":
    useConf = dev;
    break;
  case "test":
    useConf = test;
    break;
  default:
    useConf = dev;
}

const config = useConf;

export default config;
