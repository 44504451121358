import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


import { Form, Input, Result } from "antd";
import Button from "components/shared/Button";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import LoadingSpin from "components/shared/LoadingSpin";
import SupportInfoBlock from "components/forms/SupportInfoBlock";

import { getApplicantData, getValidatedApplicant, storeFinancingCompany } from "services/formService";

const ApplicantAccessOverlay = ({
  applicationToken,
  applicantToken,
  onSuccess = () => undefined,
  onFailure = () => undefined,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isServerError, setIsServerError] = useState(false);
  const [isExhausted, setIsExhausted] = useState(false);
  const [validating, setValidating] = useState(false);
  const [applicantType, setApplicantType] = useState();

  useEffect(() => {
    (async () => {
      const response = await getValidatedApplicant(applicationToken, applicantToken);
      if (!response.ok) {
        if (response.status >= 500 && response.status < 600) {
          setIsServerError(true);
        } else if (response.status === 410) {
          setIsExhausted(true);
        } else {
          onFailure();
          return;
        }
      }
      setApplicantType(response.payload.applicant_type);
      storeFinancingCompany(response.payload.form_company);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationToken, applicantToken]);

  const validateAndFetch = (data) => {
    setValidating(true);
    (async () => {
      const response = await getApplicantData(applicationToken, applicantToken, data.code);
      if (!response.ok) {
        if (response.status >= 500 && response.status < 600) {
          setIsServerError(true);
        } else if (response.status === 410) {
          setIsExhausted(true);
        } else if (response.payload.remaining_attempts) {
          form.setFields([
            {
              name: "code",
              errors: [t("leasingFormAccessValidation").replace("#", response.payload.remaining_attempts)],
            },
          ]);
        } else {
          onFailure();
          return;
        }
        setValidating(false);
      } else {
        onSuccess(response.payload);
      }
    })();
  };

  return (
    <>
      <div className="public-form public-form--go4rent-theme">
        <div className="public-form__form-block block">
          {(!!applicantType && (
            <>
              <Row>
                <Col xs={24} sm={18}>
                  {!isServerError && !isExhausted && (
                    <>
                      <Form className="form applicant-access-form" form={form} onFinish={validateAndFetch}>
                        <h3 className="invoice-verification-confirmation__header">
                          {t("leasingFormAccessValidationPrivateTitle")}
                        </h3>
                        <Row>
                          <Col>
                            <Form.Item
                              label={t("leasingFormAccessValidationCode")}
                              name={"code"}
                              rules={[
                                {
                                  required: true,
                                  message: t("leasingFormAccessValidationCode") + " " + t("isRequired"),
                                },
                                {
                                  min: 4,
                                  message: t("g4rFormFieldValueTooShort"),
                                },
                                {
                                  max: 4,
                                  message: t("g4rFormFieldValueTooLong"),
                                },
                              ]}
                            >
                              <Input.Password className={"input"} autoComplete="one-time-code" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="submit-row">
                          <Col>
                            <Button loading={validating} type="primary" size="large" htmlType="submit">
                              {t("g4rFormSubmit")}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                  {isServerError && (
                    <div>
                      <Result icon={<></>} title={t("leasingFormAccessValidationErrorServer")} />
                    </div>
                  )}
                  {isExhausted && (
                    <div>
                      <Result icon={<></>} title={t("leasingFormAccessValidationErrorExhausted")} />
                    </div>
                  )}
                </Col>
                <Col xs={24} sm={6}>
                  <Row>&nbsp;</Row>
                  <Row>
                    <SupportInfoBlock />
                  </Row>
                </Col>
              </Row>
            </>
          )) || <LoadingSpin />}
        </div>
      </div>
    </>
  );
};

export default ApplicantAccessOverlay;
