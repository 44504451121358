export const productType = {
  Factoring: "factoring",
  Loan: "loan",
  Limit: "limit",
  Leasing: "leasing",
};

export const getActiveParty = (parties) => {
  return parties?.filter((party) => party.is_main)[0];
};

export const getActiveProduct = (party) => {
  return party?.productList?.filter((product) => product.is_main)[0];
};

export const getActiveProductFromParties = (parties) => {
  if (!parties) {
    return undefined;
  }

  const activeParty = getActiveParty(parties);
  const activeProduct = getActiveProduct(activeParty);

  return activeProduct.type.toLowerCase();
};

export const getProductByType = (party, productType) => {
  return party?.productList?.filter((product) => product.type === productType.toUpperCase())[0];
};

export const getProductTypesOfMainParty = (parties) => {
  const userProductTypes = [];

  if (!parties) {
    return userProductTypes;
  }

  const activeParty = getActiveParty(parties);
  for (const product of activeParty?.productList) {
    userProductTypes.push(product.type);
  }

  return userProductTypes;
};

export const hasProductTypeInMainParty = (parties, productType) => {
  if (!parties || !productType) {
    return false;
  }

  return getProductTypesOfMainParty(parties).includes(productType.toUpperCase());
};

export const getMainProductTypeLowCase = (parties) => {
  const activeParty = getActiveParty(parties);
  const activeProduct = getActiveProduct(activeParty);

  return activeProduct?.type?.toLowerCase();
};

export const getProductFidByType = (parties, productType) => {
  const activeParty = getActiveParty(parties);
  const product = getProductByType(activeParty, productType);

  return product?.fid;
};

export const isActiveProductByType = (parties, productType) => {
  const activeParty = getActiveParty(parties);
  const activeProduct = getActiveProduct(activeParty);

  return activeProduct?.type === productType?.toUpperCase();
};

export const getUniquePermissionTypesMainParty = (parties) => {
  const uniquePermissionTypes = [];
  const activeParty = getActiveParty(parties);

  activeParty.productList.forEach((product) => {
    product.permissions.forEach((permission) => {
      if (permission.type && !uniquePermissionTypes.includes(permission.type)) {
        uniquePermissionTypes.push(permission?.type);
      }
    });
  });

  return uniquePermissionTypes;
};

export const hasPermissionInAnyParty = (parties, permissionType) => {
  if (!parties || !permissionType) {
    return false;
  }

  const uniquePermissionTypesOfAllParties = getUniquePermissionTypesMainParty(parties).includes(
    permissionType.toUpperCase()
  );
  return uniquePermissionTypesOfAllParties;
};

export const hasProductPermission = (parties, productType, permissionType) => {
  if (!parties || !productType || !permissionType) {
    return false;
  }

  const activeParty = getActiveParty(parties);
  const product = getProductByType(activeParty, productType);

  return product && product.permissions
    ? product.permissions.some((permission) => permission.type === permissionType.toUpperCase())
    : false;
};

export const getPermissions = (parties, partyFid, productType) => {
  for (const party of parties) {
    if (party.fid !== partyFid) {
      continue;
    }

    for (const product of party?.productList) {
      if (product.type !== productType.toUpperCase()) {
        continue;
      }

      return product?.permissions;
    }
  }

  return null;
};
