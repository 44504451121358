import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Logo from "components/Logo";
import LanguageSwitch from "components/shared/language/languageSwitch";
import CookiesConsent from "components/shared/CookiesConsent";

const PublicLayout = ({ formType, children }) => {
  const { t } = useTranslation();

  return (
    <div className={`App publicLayout ${formType && "formLayout"} go4rent-theme`}>
      <header className="App-header">
        <Row justify="center">
          <Col xs={20} sm={20} md={18} lg={14}>
            <div className="headerContent">
              <Logo responsive />
              <p>{t("welcomeText")}.</p>
              <LanguageSwitch
                availableLanguages={formType === "invoice-verification" ? ["en", "lt", "lv", "ee"] : ["lt"]}
                responsive={true}
                defaultLang={formType === "invoice-verification" ? undefined : "lt"}
                useLocalStorage={formType === "invoice-verification" ? false : true}
                jivochatEnabled={formType === "invoice-verification" ? true : false}
              />
            </div>
          </Col>
        </Row>
      </header>
      <section>
        <Row justify="center">
          <Col xs={22} sm={20} md={18} lg={14}>
            <div id="content">{children}</div>
          </Col>
        </Row>
      </section>
      <CookiesConsent />
    </div>
  );
};

export default withRouter(PublicLayout);
