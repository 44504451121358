import React from "react";
import { useTranslation } from "react-i18next";

import Logo from "components/Logo";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Button from "components/shared/Button";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div className={"App pageNotFound"}>
      <Row className={"header404"}>
        <Col span={14} offset={5}>
          <Row>
            <Col span={24}>
              <div className="headerContent">
                <Logo />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={"pageNotFoundMsg"}>{t("pageNotFound")}</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={"content404"}>
        <Col span={10} offset={7}>
          <p>
            {t("pageNotFoundMsgLine1")}
            <br />
            {t("pageNotFoundMsgLine2")}
          </p>
          <Button type="primary" size="large" onClick={() => { window.location.href = "https://go4rent.lt/" }}>
            {t("pageNotFoundGoToHome")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NotFoundPage;
