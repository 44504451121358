import settings from "../config/config";
import { getStoredFinancingCompany } from "services/formService";

export const getAuthHeader = (token) => {
  const savedToken = localStorage.getItem("token");
  const financingCompany = getStoredFinancingCompany();

  return {
    headers: {
      Authorization: "Bearer " + (!token ? (savedToken ? savedToken.replace(/"/g, "") : savedToken) : token),
      ...(financingCompany ? { From: financingCompany } : null),
    },
  };
};

export const getCmsAuth = () => ({
  headers: {
    Authorization: getCmsTokenValue(),
  },
});

export const getCmsTokenValue = () => settings.cmsToken;
