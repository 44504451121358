import React from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import HiddenInput from "components/shared/form/HiddenInput";

import Email from "components/forms/formElements/Email";
import PhoneNumber from "components/forms/formElements/PhoneNumber";
import PersonalCode from "components/forms/formElements/PersonalCode";
import MandatoryInputLimited from "components/forms/formElements/MandatoryInputLimited";

import { stripNumbers } from "utils/formUtils";

const PrivateCustomerInformationBlock = ({ formInstance, asRelatedApplicant = false, readOnly }) => {
  const { t } = useTranslation();

  const setFieldValue = (value, field) => {
    formInstance.setFieldsValue({
      applicant: {
        [field]: stripNumbers(value),
      },
    });
  };

  return (
    <>
      <HiddenInput name={["applicant", "type"]} />
      <HiddenInput name={["applicant", "role"]} />
      {!asRelatedApplicant ? <h3 className="leasing-request-form__heading">{t("g4rFormYourInformation")}</h3> : null}
      <Row>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={t("g4rFormGuarantorName")}
            name={["applicant", "name"]}
            max={29}
            readOnly={readOnly}
            onChange={(value) => setFieldValue(value, "name")}
          />
        </Col>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={t("g4rFormGuarantorSurname")}
            name={["applicant", "surname"]}
            max={40}
            readOnly={readOnly}
            onChange={(value) => setFieldValue(value, "surname")}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PersonalCode
            label={t("g4rFormGuarantorPersonalCode")}
            name={["applicant", "code"]}
            readOnly={asRelatedApplicant || readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <PhoneNumber
            form={formInstance}
            label={t("g4rFormGuarantorPhoneNumber")}
            name={["applicant", "phone"]}
            readOnly={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Email label={t("g4rFormGuarantorEmail")} name={["applicant", "email"]} readOnly={readOnly} />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited label={t("g4rFormCity")} name={["applicant", "city"]} max={30} readOnly={readOnly} />
        </Col>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={t("g4rFormAddress")}
            name={["applicant", "address"]}
            max={100}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </>
  );
};

export default PrivateCustomerInformationBlock;
