import React from "react";
import { useTranslation } from "react-i18next";

import Input from "components/shared/form/Input";

const CompanyCode = ({ name = "company_code", label, readOnly = false }) => {
  const { t } = useTranslation();

  return (
    <Input
      label={label || t("leasingFormCompanyCode")}
      name={name}
      rules={[
        {
          pattern: /^\d+$/,
          required: true,
          message: (label || t("leasingFormCompanyCode")) + " " + t("isRequired"),
        },
        {
          max: 9,
          message: t("g4rFormFieldValueTooLong"),
        },
        {
          min: 9,
          message: t("g4rFormFieldValueTooShort"),
        },
      ]}
      disabled={readOnly}
    />
  );
};

export default CompanyCode;
