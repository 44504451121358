import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Input from "components/shared/form/Input";

import { generateTranslationString } from "utils/helpers";

const AssetInformationDisplayBlock = ({ formInstance }) => {
  const { t } = useTranslation();
  const language = i18next.language;

  const [dynamicFieldContent, populateDynamicFields] = useState();

  useEffect(() => {
    populateDynamicFields(constructTable());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const fieldSetMandatoryOnly = [
    {
      name: ["lending", "brand"],
      label: t("g4rFormBrand"),
    },
    {
      name: ["lending", "model"],
      label: t("g4rFormModel"),
    },
    {
      name: ["lending", "manufacturing_year"],
      label: t("g4rFormYearMade"),
      translate: (value) => (value === " " ? t("g4rFormYearMadeOther") : value),
    },
    {
      name: ["lending", "fuel_type"],
      label: t("g4rFormFuelType"),
      translate: (value) => t(generateTranslationString(value, "FuelType")),
    },
  ];

  const generateFields = () =>
    fieldSetMandatoryOnly
      .filter((field) => formInstance.getFieldValue(field.name))
      .map((field) => {
        if (field.translate) {
          formInstance.setFieldsValue(
            ["translation"]
              .concat(field.name)
              .reverse()
              .reduce((result, item) => {
                return { [item]: result };
              }, field.translate(formInstance.getFieldValue(field.name)))
          );
          return (
            <>
              <Input name={["translation"].concat(field.name)} label={field.label} disabled />
              <Input name={field.name} className="hidden" />
            </>
          );
        } else {
          return <Input name={field.name} label={field.label} disabled />;
        }
      });

  const constructTable = () => {
    return generateFields()
      .reduce((result, field, index) => {
        if (!(index % 2)) {
          result[(index / 2) | 0] = [];
        }
        result[(index / 2) | 0].push(field);
        return result;
      }, [])
      .map((row, rowIndex) => {
        return (
          <Row key={rowIndex}>
            {row.map((field, colIndex) => (
              <Col xs={24} sm={12} key={colIndex}>
                {field}
              </Col>
            ))}
          </Row>
        );
      });
  };

  return (
    <>
      <h3 className="invoice-verification-confirmation__header">{t("g4rFormPropertyInfo")}</h3>
      <Row>
        <Col span={24}>
          <Input name={["lending", "property_value"]} label={t("leasingFormPropertyValue")} disabled />
        </Col>
      </Row>
      {dynamicFieldContent}
    </>
  );
};

export default AssetInformationDisplayBlock;
