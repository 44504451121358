import Modal from "components/shared/Modal";
import cx from "classnames";
import { List } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";

import closeIcon from "../../assets/icons/icon_close.svg";
import ExternalLogo from "components/shared/ExternalLogo";
import LoadingSpin from "./LoadingSpin";

const ModalListSelect = ({ options, onFinish, headingText, okText, cancelText = undefined, visible = true }) => {
  const [selected, setSelected] = useState();
  const [isLoading, setLoading] = useState(false);

  const confirm = () => {
    setLoading(true);
    onFinish(selected);
  };

  const cancel = () => {
    setSelected(null);
    onFinish(null);
  };

  const clickItem = (id) => {
    // allow unselect
    setSelected(id === selected ? null : id);
  };

  return (
    <Modal
      className="modal-list-select"
      closeIcon={<img src={closeIcon} alt="close" onClick={cancel} />}
      visible={visible}
      okText={okText}
      okButtonProps={{
        className: "button--small button--theme-go4rent",
        disabled: !selected,
        loading: isLoading,
      }}
      onOk={confirm}
      cancelText={cancelText}
      cancelButtonProps={{
        style: cancelText ? null : { display: "none" },
        className: "button--small button--theme-go4rent",
        disabled: !selected,
        loading: isLoading,
      }}
      onCancel={cancel}
      closable={true}
      destroyOnClose={true}
      afterClose={cancel}
    >
      <h1 className="modal-list-select__heading">{headingText}</h1>
      {(options && options.length && (
        <List
          itemLayout="horizontal"
          dataSource={options}
          renderItem={(item) => (
            <List.Item
              className={cx("modal-list-select__list-item", {
                "modal-list-select__list-item": item.id === selected,
              })}
              onClick={() => clickItem(item.id)}
            >
              <List.Item.Meta
                avatar={<ExternalLogo name={item.img} externalSrc={item.externalImg} />}
                title={
                  <div className="modal-list-select__title-box">
                    <h3 className="modal-list-select__title">{item.name}</h3>
                    <CheckCircleOutlined
                      className={cx("modal-list-select__success-icon", {
                        "modal-list-select__success-icon--active": item.id === selected,
                      })}
                    />
                  </div>
                }
                description={item.description}
              />
            </List.Item>
          )}
        />
      )) || <LoadingSpin />}
    </Modal>
  );
};

export default ModalListSelect;
